import { createReducer, on } from '@ngrx/store'
import { StoreInfo } from '../../models/home.model'
import { storeInfoUpdate } from './store-info.actions'

export const initialState: StoreInfo = {
  storeSlug: '',
  brandLogo: 'assets/images/logo/logo-ideku-black.png?12',
  bannerDesktop: [],
  bannerMobile: [],
  name: 'IDEFOOD',
  announcementEnable: false,
  announcementMessage: '',
  template: '',
  contacts: [],
  fontStyle: 'Poppins, sans-serif',
  colorPrimary: '#00489a',
  colorSecondary: '#c9e7ff',
  colorFont: '#404040',
  colorTextButtonPrimary: '#ffffff',
  colorTextButtonSecondary: '#00489a',
  shopClosed: false,
  shopOperationalOpenAt: 0,
  shopOperationalClosedAt: 0,
  shopHoliday: false,
  shopHolidayOpenAt: 0,
  shopHolidayClosedAt: 0,
  currency: '',
  country: '',
  configService: [],
  roundingModel: {
    roundingMethod: '',
    roundingNearest: '',
    pricingType: ''
  },
  serviceType: [],
  timeZone: '',
  operatingTiming: [],
  operatingTimingSelected: {
    day: '',
    dayIndex: 0,
    operationHoursModelList: [
      {
        timeStartAt: '',
        timeEndAt: ''
      }
    ],
    twentyFourHour: false
  },
  deliveryTimingSelected: {
    day: '',
    dayIndex: 0,
    operationHoursModelList: [
      {
        timeStartAt: '',
        timeEndAt: ''
      }
    ],
    twentyFourHour: false
  },
  isLoading: true,
  merchantId: 0,
  storeId: 0,
  qr: '',
  isMerged: false,
  digitsInfo: '',
  isTableManagementActive: false,
  isUseLoyalty: false,
  orderSessionHash: '',
  locale: '',
  code: '',
  unclaimedPointsData: {
    merchantId: 0,
    storeId: 0,
    orderId: 0,
    pointAmount: 0,
    allPoints: 0,
    alreadyClaimed: false,
    invoiceId: ''
  },
  isQueue: false,
  tipping: {
    enable: false,
    serviceType: [],
    products: [],
    type: '',
    tippingValue: []
  },  
  backgroundStyle: '',
  deliveryTiming: [],
  scheduledOrder: {
    isEnabled: false,
    leadTime: {
      days: 0,
      hours: 0
    },
    serviceModeConfig: [],
    maxDeliveryDays: 0
  },
  recommendationConfig: {
    recommendation: {
      enable: false,
      title: ''
    },
    upselling: {
      enable: false,
      title: ''
    }
  }
}

export const storeInfoReducer = createReducer(
  initialState,
  on(storeInfoUpdate, (state, storeInfo) => ({
    ...state,
    ...storeInfo
  }))
)
