import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { Params } from '@angular/router'
import { CustomMenu } from '@models/custom-menu.model'
import { StoreInfo } from '@models/home.model'
import { Categories, Product } from '@models/product.model'
import { Response } from '@models/response'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HomeServiceImpl {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public readonly platformId: string
  ) {}

  getCategories(
    storeSlug: string,
    queryParams: any
  ): Observable<Response<Categories[]>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('pageSize', queryParams.pageSize ?? 10)
      .set('pageNumber', queryParams.pageNumber ?? 0)

    return this.http.get<Response<Categories[]>>(
      `/api/idefood/${storeSlug}/homepage/categories`,
      {
        headers: headers,
        params: params
      }
    )
  }

  getProductDetail(
    storeSlug: string,
    productSlug: string,
    serviceType: string
  ): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams().set('serviceType', serviceType ?? '')

    return this.http.get<Response<[]>>(
      `/api/idefood/${storeSlug}/homepage/product/${productSlug}`,
      {
        headers: headers,
        params: params
      }
    )
  }

  getProducts(
    storeSlug: string,
    queryParams: any
  ): Observable<Response<Product>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('pageSize', queryParams.pageSize ?? 10)
      .set('pageNumber', queryParams.pageNumber ?? 0)
      .set('serviceType', queryParams.serviceType ?? '')
      .set('dataQuery', queryParams.dataQuery ?? '')
      .set('sortDirection', queryParams.sortDirection ?? '')

    return this.http.get<Response<Product>>(
      `/api/idefood/${storeSlug}/homepage/products`,
      {
        headers: headers,
        params: params
      }
    )
  }

  getStoreInfo(storeSlug: string): Observable<Response<StoreInfo>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this.http.get<Response<StoreInfo>>(
      `/api/idefood/${storeSlug}/homepage`,
      {
        headers: headers
      }
    )
  }

  getStoreInfoById(storeId: number, merchantId: number): Observable<Response<StoreInfo>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('storeId', storeId)
      .set('merchantId', merchantId)

    return this.http.get<Response<StoreInfo>>(
      `/api/idefood/info`,
      {
        headers,
        params
      }
    )
  }

  getCustomMenu(
    storeSlug: string,
    serviceType: string
  ): Observable<Response<CustomMenu>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams().set('serviceType', serviceType ?? '')

    return this.http.get<Response<CustomMenu>>(
      `/api/idefood/${storeSlug}/homepage/customMenu`,
      {
        headers: headers,
        params: params
      }
    )
  }

  getProductsRecommendation(
    storeSlug: string,
    queryParams?: Params
  ): Observable<Response<Product>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()

    for (let key in queryParams) { 
      params = params.set(key, queryParams[key])
    }

    return this.http.get<Response<Product>>(
      `/api/idefood/${storeSlug}/homepage/products/recommendation`,
      {
        headers: headers,
        params: params
      }
    )
  }
}
